//*----------  COMPONENTS  ----------*/
import DataGrid from '@/components/DataGrid/DataGridSimple/DataGridSimple.vue'
//*----------  VUEX  ----------*/
import service        from '@/services/soporte'
import authStore              from '@/store/modules/authStore'

import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'

export default {
    name : "User",
    components : {
        DataGrid
    },
    data : () => ({
        configGrid : {
            summary : true,
            paging : {
                properties : {
                    "page-size" : 25
                }
            },
            export : {
                export          : true,
                exportSelected  : true,
                exportName      : "Servicios",
                texts           : { exportAll : "Exportar toda la información", exportSelectedRows  : "Exportar filas seleccionadas" } 
            },
        },
        actions : [],
        dataColumns: [
            {
                "data-field"    : "id_adminCtUser",
                caption         : "ID",
                column          : "id_adminCtUser",
                "sort-order"    : "asc"
            }, {
                "data-field"  : "name",
                caption       : "Usuario",
                column        : "name",
            }, {
                "data-field"  : "email",
                caption       : "Correo",
                column        : "email",
            },{
                "data-field"  : "perfil",
                caption       : "Tipo Usuario",
                column        : "perfil",
            }, {
                "data-field"  : "active",
                caption       : "Activo",
                column        : "active",
            }, {
                "data-field" : "fechaC",
                caption      : "Fecha Creación",
                column       : "fechaC",
            }, {
                "data-field" : "userC",
                caption      : "Usuario Creación",
                column       : "userC",
            }, {
                "data-field" : "fechaU",
                caption      : "Fecha Modificación",
                column       : "fechaU",
            }, {
                "data-field" : "userU",
                caption      : "Usuario Modificación",
                column       : "userU",
            }
        ],
        formatExcel     : {
            fileName    : 'Usuarios.xlsx',
            worksheet   : 'Usuarios',
            salidas     : {
                factor : 1,
                factorG: 1
            },
        }
    }),
    computed : {
       ...mapGetters("User", {
           dataSource : "getDataSource"
        }),
    },
    methods : {
        ...mapActions("User",  ["setDataSource"] ),
        eventActionTable({ event, dataAction }) {
            this.$emit("eventActionTable", { event, dataAction })
        },
        setDataGridRef( dataGrid ) {
            this.$emit("setDataGridRef", dataGrid)
        }
    },
    created() {
        service.SopR_dataSourceUsers().then( result => {
            this.setDataSource( this.$JsTable.toObj( result.data ))
        })
    },    
}
