//*----------  COMPONENTS  ----------*/
import service        from '@/services/administracion'
import serviceS       from '@/services/soporte'
import DialogForm     from '@/components/Dialogs/DialogForm/DialogForm.vue'

import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'

var currentDate = new Date().toISOString().substr(0,10);
export default {
    name : "Ticket",
    components : {
        DialogForm
    },
    props : {
        dataConcepto: Object,
        dialog : {
            type    : Boolean,
            default : false
        },
        isNew : {
            type    : Boolean,
            default : true
        },
        oldObject : {
            type    : Object,
            default : () => {}
        },
    },
    data : () => ({
        title       : "Nuevo",
        valid       : true,
        lazy        : false,
        idUser      : -1,
        idPersona   : -1,
        nombre      : undefined,
        primerA     : undefined,
        segundoA    : undefined,
        correo      : undefined,
        userName    : undefined,
        password    : undefined,
        errores     : {
            password : false,
        },
        rules     : {
            password : [v => !!v || 'La contrasela es requerida'],
        },
        items     : {
            perfil  : [],
            tipo    : []
        }
    }),
    watch : {
        'oldObject'  : function() {
            if ( Object.keys(this.oldObject).length > 0 ) {
                this.idUser    = this.oldObject.adminCtUser.id_adminCtUser;
                this.idPersona = this.oldObject.baseCtPersona.id_baseCtPersona;
                this.nombre    = this.oldObject.baseCtPersona.nombre;
                this.primerA   = this.oldObject.baseCtPersona.primerApellido;
                this.segundoA  = this.oldObject.baseCtPersona.segundoApellido;
                this.correo    = this.oldObject.adminCtUser.email;
                this.perfil    = this.oldObject.adminCtUser.id_adminCtPerfil;
                this.userName  = this.oldObject.adminCtUser.userName;
                this.tipo      = this.oldObject.adminCtUser.id_adminCtTipoUsuario;
            } else {
                this.idUser     = undefined;
                this.idPersona  = undefined;
                this.nombre     = undefined;
                this.primerA    = undefined;
                this.segundoA   = undefined;
                this.correo     = undefined;
                this.perfil     = undefined;
                this.userName   = undefined;
                this.tipo       = undefined;
            }
        }
    },
    computed : {
    },
    methods : {
        ...mapActions("DialogForm",["setDialog"]),
        ...mapActions("User",  ["setDataSource"] ),
        getTitle() {
            return "Cambiar contraseña";
        },
        eventClose () {
            this.reset();
            this.setDialog({
                key    : "dlgChangePass",
                status : false
            });
        },
        eventGen () {
            if ( this.validate ) {
                this.updatePassword();
            }
        },
        updatePassword() {
            let arrInfo = [];
            let objPass = {
                id_adminCtUser  : this.idUser,
                password        : this.password,
                updated_at      : this.oldObject.baseCtPersona.updated_at
            }
            arrInfo.push(this.$JsTable.getFormat("adminCtUser",   "Update", [ objPass ],  ["id_adminCtUser"]));
            service.AdmU_passwordUser( arrInfo ).then( result => {
                if( this.$Msg.fn( {data : result, message : "Contraseña actualizada correctamente" } )) {
                    this.eventClose();
                    this.readTableUsers();
                }
            })
        },
        reset () {
            this.$refs.form.reset();
            this.idUser     = undefined;
            this.idPersona  = undefined;
            this.nombre     = undefined;
            this.primerA    = undefined;
            this.segundoA   = undefined;
            this.correo     = undefined;
            this.userName   = undefined;
        },
        validate () {
            return this.$refs.form.validate()
        },
        readTableUsers() {
            serviceS.SopR_dataSourceUsers().then( result => {
                this.setDataSource( this.$JsTable.toObj( result.data ))
            })
        },
    },
    created() {
        service.AdmR_dataCreateUser().then( result => {
            this.items.perfil = this.$JsTable.toObj( result.data.perfil );
            this.items.tipo   = this.$JsTable.toObj( result.data.tipo );
        })
    },
}
