import operationsR   from './operations/R'
import operationsC   from './operations/C'
import operationsU   from './operations/U'

const administracion = ( () => ({
    ...operationsR,
    ...operationsC,
    ...operationsU
}))()

export default administracion