<template> <!-- menu en conceptos -->
    <v-list
        nav
        dense="">
        <router-link :to="{ path: '/home'}" replace>
            <v-list-item                         
                class="listMod">
                <v-list-item-icon>
                    <v-icon>mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="">
                    <v-list-item-title>Home</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </router-link>
        <v-divider></v-divider>
        <v-list-group 
            v-for="item in elements" 
            :key="item.id" 
            active-class="activeClass">            
            <template v-slot:activator>                
                <v-list-item>
                    <v-list-item-action >
                    <v-icon>mdi-tag</v-icon>
                </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>                
            </template>
            <router-link 
                v-for="subItem in item.conceptos" 
                :key="subItem.title" 
                :to="'/' + subItem.link" replace>
                <template v-if="isConcepto(subItem.format)">
                    <v-list-item>                
                        <v-list-item-content>
                            <v-list-item-title>
                                * {{ subItem.name }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </router-link>
            <v-divider></v-divider>
        </v-list-group>
    </v-list>
</template>
<script>
import {mapActions} from 'vuex'
export default {
    props : {
        elements : Array
    },
    methods : {
        isConcepto( arrFormat ) {
            let concepto = false;
            if ( arrFormat.findIndex( format => format == "concepto") != -1 )
                concepto = true;
            return concepto;
        }
    }
}
</script>

<style>

</style>
