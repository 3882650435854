<template> <!-- menú en home -->
    <v-list 
        nav 
        dense>
        <v-list-item-group 
            mandatory 
            v-model="manda" 
            active-class="activeClass" 
            color="primary">
            <v-list-item 
                @click.stop="setCurrentModule('principal')" 
                class="listMod">
                <v-list-item-icon>
                    <v-icon>mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="">
                    <v-list-item-title>Home</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <div v-for="(modulo, key) in modulos" :key="key">
                <v-list-item @click.stop="setModule(modulo.id)">
                    <v-list-item-icon>
                        <v-icon v-text="modulo.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title v-text="modulo.name"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </div>
        </v-list-item-group>
    </v-list>
</template>

<script>
import {mapActions} from 'vuex'
export default {    
    data : () => ({
        manda: 0
    }),
    props : {
        modulos : Array
    },
    methods : {        
        ...mapActions("home",["setCurrentModule","setIdModulo", "setNavState"]),
        setModule: function(id){
            // *** 20210102~LuisLópez al ser movil oculta navigation
            if(this.$mq == 'md' || this.$mq == 'sm') 
                this.setNavState({
                    nav : "draw",
                    val : false
                })
            this.setCurrentModule("card")
            this.setIdModulo(id)
        }
    }
}
</script>

<style>

</style>
