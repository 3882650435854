import {    
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxPager,
    DxPaging,
    DxSummary,
    DxTotalItem,
    DxGroupItem,
    DxGroupPanel,
    DxGrouping,
    DxHeaderFilter,
    DxFilterRow,
    DxExport,
    DxSelection,
} from 'devextreme-vue/data-grid';
import { mapGetters, mapState } from 'vuex';
import exportTable from '../../../../src/libs/ExportJS.js'

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxSummary,
        DxTotalItem,
        DxGroupItem,
        DxPager,
        DxPaging,
        DxSearchPanel,
        DxGroupPanel,
        DxGrouping,
        DxHeaderFilter,
        DxFilterRow,
        DxExport,
        DxSelection,
    },
    props :{
        propDataSource : { 
            type    : Array,
            required: true
        },
        propColumns:{      
            type   : Array,
            default: () => []
        },
        propPropertiesGrid:{
            type : Object,
            default : () => {}
        },
        propConfigGrid : {
            type : Object,
            default : () => {}
        },
        propDataTemplate : {
            type : Array,
            default : () => []
        },
        propFormatExcel : {
            default : () => {}
        },
        propButtons : {
            default : () => []
        },
        propMainGrid : {
            default : () => false
        },
        propRefName : {
            type    : String,
            default : () => 'dataGrid'
        },
        prodDataGrid : {
            type    : Object,
            default : () => {}
        }
    },
    data : () => ({
        totalItemsC    : [],
        groupItemsC    : [],
        configTemplate : {},
        templates      : [],
    }),
    computed : {
        ...mapGetters("auth", {
            gridAccessCol: "getComponentAccess"
        }),
        ...mapState("appbar",  {
            actionsTable : "icons"
        }),
        dataSource(){ // Fuente de datos para dataGrid
            return this.propDataSource
        },
        columns(){ // Definición de columnas
            let max = this.propColumns.length;
            for(let x=0; x < max; x++) {
                if (this.propColumns[x]['data-field'] !== undefined ? this.gridAccessCol[this.propColumns[x]['data-field']] === false : false)
                    this.propColumns[x]['visible'] = false
            }
            setPropColumns({
                columns        : this.propColumns,
                totalItemsC    : this.totalItemsC,
                groupItemsC    : this.groupItemsC,
                configTemplate : this.configTemplate
            })        
            return [...this.propColumns]
        },
        dataGridProperties(){ // propiedades del dataGrid
            return {
                ...dxDataGridDefaultProperties,
                ...this.propPropertiesGrid
            }
        },
        configGrid(){ // configuración de varios elementos de dataGrid
            return {
                ...configGridDefaultValues,
                ...this.propConfigGrid
            }
        },
        totalItems(){ // Sumatoria por columnas 
            return this.totalItemsC
        },
        groupItems(){ // Sumatoria por grupos
            return this.groupItemsC
        },
        groupTemplate(){
            return {
                ...this.propDataTemplate
            }
        },
        actionsButtons () {
            return this.propMainGrid ? this.createActions() : this.propButtons;
            /* 
                *   https://js.devexpress.com/Documentation/Guide/Themes_and_Styles/Icons/#Built-In_Icon_Library
                *   De aqui se pueden obtener los iconos para las tablas de devExtreme
            */
        }
    },
    methods :{
        calculateRow(options){
            this.$emit("calculateRow", options)
        },
        customizeExcelCell(options) { // ! FIXME: Validar cuando se debe de quitar
            this.$emit("customizeExcelCell", options)
        },
        exporting(e) {
            exportTable.exporting(e, this.propFormatExcel)
        },
        getColor({
            field,
            val 
        }){
            let color  = "",
                config = this.configTemplate[field];
            if( config.hasOwnProperty("minVal") && val < config.minVal )
                color = config.minColor
            if( config.hasOwnProperty("maxVal") && val > config.maxVal )
                color = config.maxColor
            return   color + "--text"               
        },
        getFormatValue( value ) {
            return parseFloat(value).toFixed(2);
        },
        /* *
        * Funciones para generar las acciones dentro de la tabla principal
        * 1. Es necesario indicar que se encuentra en la tabla principal
        * 2. Si se desea agregar un nuevo boton es necesario, dar de alta una nueva función, ya que dataGrid Solo reacciona a los clicks por una fución en especifico
        ? 3. https://js.devexpress.com/Documentation/Guide/Themes_and_Styles/Icons/#Built-In_Icon_Library      Link para ver los iconos disponibles
        * 4. Es necesario poner una fucion que reciba las emciones que se realizan desde DataGridSimple "@eventActionTable="eventActionTable""
        */
        eventActionTable( objData ) { // ! Para las acciones que tiene la tabla
            this.$emit("eventActionTable", objData )
        },
        iconClickEdit( event ) {
            this.$emit("eventActionTable", this.getObjEvent( event, "btnEdicion" ) )
        },
        iconClickCancel( event ) {
            this.$emit("eventActionTable", this.getObjEvent( event, "btnCancelado" ) )
        },
        iconClickExportTr( event ) {
            this.$emit("eventActionTable", this.getObjEvent( event, "btnExportTr" ) )
        },
        iconClickCerrar( event ) {
            this.$emit("eventActionTable", this.getObjEvent( event, "btnCerrar" ) )
        },
        iconClickEliminar( event ) {
            this.$emit("eventActionTable", this.getObjEvent( event, "btnEliminar" ) )
        },
        iconClickActive( event ) {
            this.$emit("eventActionTable", this.getObjEvent( event, "btnActive" ) )
        },
        iconClickAsignar( event ) {
            this.$emit("eventActionTable", this.getObjEvent( event, "btnAsignar" ) )
        },
        iconClickAtender( event ) {
            this.$emit("eventActionTable", this.getObjEvent( event, "btnAtender" ) )
        },
        iconClickPausar( event ) {
            this.$emit("eventActionTable", this.getObjEvent( event, "btnPausar" ) )
        },
        iconClickFinalizar( event ) {
            this.$emit("eventActionTable", this.getObjEvent( event, "btnFinalizar" ) )
        },
        iconClickEditGral( event ) {
            this.$emit("eventActionTable", this.getObjEvent( event, "btnEditGral" ) )
        },
        iconClickChangePass( event ) {
            this.$emit("eventActionTable", this.getObjEvent( event, "btnPassword" ) )
        },
        getObjEvent( event, idBtn ) {
            let actions = [ ...this.actionsTable ];
            let dataAction = actions.find( ele => ele.idBtn == idBtn )
            let obj = { event, dataAction };
            return obj;
        },
        createActions() {
            let len = this.actionsTable.length;
            let actions = [];
            let funcIconClick = {
                'btnEdicion'    : this.iconClickEdit,
                'btnCancelado'  : this.iconClickCancel,
                'btnExportTr'   : this.iconClickExportTr,
                'btnCerrar'     : this.iconClickCerrar,
                'btnEliminar'   : this.iconClickEliminar,
                'btnActive'     : this.iconClickActive,
                'btnAsignar'    : this.iconClickAsignar,
                'btnAtender'    : this.iconClickAtender,
                'btnPausar'     : this.iconClickPausar,
                'btnFinalizar'  : this.iconClickFinalizar,
                'btnEditGral'   : this.iconClickEditGral,
                'btnPassword'   : this.iconClickChangePass
            };
            let classIconClick = {
                'btnEdicion'    : "edit",
                'btnCancelado'  : "cancel",
                'btnExportTr'   : "excel",
                'btnCerrar'     : "close",
                'btnEliminar'   : "delete",
                'btnActive'     : "active",
                'btnAsignar'    : "card",
                'btnAtender'    : "clock",
                'btnPausar'     : 'equal',
                'btnFinalizar'  : 'check',
                'btnEditGral'   : 'new',
                'btnPassword'   : 'changePass'
            };
            for( let i = 0; i < len; i++ ) {
                let current = this.actionsTable[i]
                actions.push({
                    hint     : current.accion,
                    icon     : current.urlIcon,
                    visible  : true,
                    cssClass : classIconClick[current.idBtn],
                    onClick  : funcIconClick[current.idBtn]
                });
            }
            return actions;
        }
    },
    mounted(){
        let dataGrid = this.$refs[this.propRefName].instance;
        this.$emit("setDataGridRef", dataGrid)
    }
};
// Valores por defecto de dataGrid
const dxDataGridDefaultProperties = {
    "show-column-lines"      : true,
    "show-row-lines"         : true,
    "show-borders"           : true,
    "row-alternation-enabled": true,
    "allow-column-reordering": true,
    "allow-column-resizing"  : true,
    "column-min-width"       : 50,
    "column-auto-width"      : true,
    "height"                 : 600
}
// Valores por defecto a columnas 
const dxColumDefaultValues = {
    "data-type"     : "string",
    "alignment"     : "right",
    "group-index"   : -1,
    "format"        : "",
    "cell-template" : ""
}
// Valores por defecto de dataGrid
const configGridDefaultValues = {
    summary   : false,
    search    : {
        val : true,
        properties : {
            "highlight-case-sensitive" : true,
            placeholder : "Buscar..."
        }
    },
    pager     : {
        val : true,
        properties : {
            "allowed-page-sizes" : [10, 25, 50, 100],
            "show-page-size-selector" : true
        }
    },
    paging    : {
        val : true,
        properties : {
            "page-size" : 10
        }
    },
    groupPanel: false,
    expandAll : false,   
}
/**
 * @param {Object} propsColumns columnas seteadas mediante props, variables para total y grupos
 * @description Añadido de valores por defecto a columnas y revisión 
 *  de otras propiedades
 */
const setPropColumns = ({
    columns,
    totalItemsC,
    groupItemsC,
    configTemplate
}) => {    
    // Armado de objeto para item de grupo y totales
    const setItemInfo = obj => {
        let aux =    {
            name                  : obj.name || "",
            "show-in-column"      : obj.showInColumn,
            "align-by-column"     : obj.alignByColumn || true,
            "summary-type"        : obj.summaryTypeTotal || obj.summaryTypeGroup,
            "display-format"      : obj.displayFormat || "{0}",
            "value-format"        : obj.valueFormat || "",
            "show-in-group-footer": obj.showInGroupFooter || false,
        }
        // si la columa es diferente de "custom" se añade la columna "data-field"
        if(obj.summaryTypeTotal != "custom" || obj.summaryTypeGroup != "custom" )
            aux.column = obj["column"];
        return aux
    }
     

    var index  = columns.length,
    currentObj = {};
    for (let i = 0; i < index; i++) {
        currentObj = columns[i];
        // Seteo de valores por defecto
        currentObj = {
            ...dxColumDefaultValues,
            ...currentObj
        }     
        // Revisión de valores de tipo grupo
        if( currentObj.hasOwnProperty("summaryTypeGroup") ) 
            groupItemsC.push( setItemInfo(currentObj) );
        // Revisión de valores de tipo total
        if( currentObj.hasOwnProperty("summaryTypeTotal") ) 
            totalItemsC.push( setItemInfo(currentObj) );
        //Revisión de valores para uso de cellTemplate
        if( currentObj.hasOwnProperty("templateConfig") ) 
            configTemplate[ currentObj.field ] = currentObj.templateConfig
    }
}
