<template>
    <v-btn-toggle dense group class="iconAppbar">
        <div class="text-center">
            <template v-for="icon in icons">
                <v-tooltip bottom :key="icon.idRlCA">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="mx-2 btnPermiso"
                            fab
                            dark
                            color="rgb(57, 153, 156)"
                            small
                            v-bind="attrs" v-on="on"
                            :key="icon.idRlCA" @click="setAction(icon.idRlCA,icon.idBtn)">
                                <v-img :src="icon.urlIcon" class="imgPermiso"> </v-img>
                        </v-btn>
                    </template>
                    <span>{{icon.accion}}</span>
                </v-tooltip>
            </template>
        </div>
    </v-btn-toggle>
</template>
<style>
/*     .mx-2.btnPermiso {
        border: 2px solid !important; 
        border-image-source: linear-gradient(45deg, rgb(0,143,104), rgb(250,224,66));
        border-image-slice: 1;
        border-image:  radial-gradient(
            circle,
            white 0%,
            white 14px,
            rgba(0, 0, 0, 0.3) 18px,
            rgba(0, 0, 0, 0
            ) 19px) !important;
    } */
    .imgPermiso {
        filter: invert(100%) sepia(0%);
    }
    .v-image__image, .v-image__placeholder{
        width: 68% !important;
        height: 68% !important;
        margin-top: 16% !important;
        margin-left: 16% !important;
    }
</style>
<script>
import {
    mapActions,
    mapGetters,
    mapState
} from 'vuex'
import service      from '@/services/base'
import getFile      from '../../../services/getFile'
export default {
    props :{
        data : Object,
        drawerRight : {
            type    : Boolean,
            default : false
        } 
    },
    data : () => ({
        icons      : [],
        tile       : true,
    }),
    methods : {
        ...mapActions( "appbar", ["setActionsTable"] ),
        setAction( idRlCA, idBtn ) { this.$emit("emitEvent", {idRlCA, idBtn} ) },
    },
    created() {
        if( this.data != undefined ) {
            if( this.data.sideMenu.name != "Reportes" ) {
                service.BasR_getAccionesConcepto( { idConcepto : this.data.concepto.id } ).then( result => {
                    let objActions = this.$JsTable.toObj( result.data );
                    let menuActions = [];
                    let tableActions = [];
                    objActions.forEach(element => {
                        element.urlIcon = element.menu == 1 ? 'https://api.desarrollo-web.mx'+getFile.url(2, 0, element.urlIcon) : element.urlIcon;
                        element.menu == 1 ? menuActions.push(element) : tableActions.push(element)
                    } );
                    this.icons = menuActions;
                    this.setActionsTable( tableActions )
                    this.$emit( "setActionsTable", { key: this.data.concepto.id, data: tableActions} );
                })
            }
        }
    },
    mounted() {
    }
}
</script>
