import setApi from '@/services/setApi';

const R = ( ( ) => {
    let parametros = {
        modulo  : 'administracion',
        abrev   : 'Adm',
        seccion : 'OPS',
        accion  : 'R',
        request : [
            "dataCreateUser"
        ]
    }    
    return setApi(parametros)
})()

export default R