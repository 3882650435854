import api from '@/services/api';

const getFile = {}

getFile.binary = async (id_baseCtDocModulo, id, fileName) => {
    return await load({ servicio: 'md', docModulo: id_baseCtDocModulo, id, fileName, ls: false})
}

getFile.directory = async (id_baseCtDocModulo, id) => {
    return await load({ servicio: 'md', docModulo: id_baseCtDocModulo, id, ls : true })
}

getFile.url = (id_baseCtDocModulo, id, fileName) => {
    return `/api/docs?srv=md&dm=${id_baseCtDocModulo}&id=${id}&fn=${fileName}`
}

const load = async (params) => {
    const {servicio, docModulo, fileName, id, ls} = params
    let url = `srv=${servicio}&dm=${docModulo}&id=${id}&${ls === true ? `ls=${ls}` : `fn=${fileName }` }`
    return await api.get(`/docs?${url}`, {}, 'GET')
}
export default getFile
