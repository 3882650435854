//*----------  COMPONENTS  ----------*/
import service        from '@/services/administracion'
import serviceS       from '@/services/soporte'
import DialogForm     from '@/components/Dialogs/DialogForm/DialogForm.vue'

import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'

var currentDate = new Date().toISOString().substr(0,10);
export default {
    name : "Ticket",
    components : {
        DialogForm
    },
    props : {
        dataConcepto: Object,
        dialog : {
            type    : Boolean,
            default : false
        },
        isNew : {
            type    : Boolean,
            default : true
        },
        oldObject : {
            type    : Object,
            default : () => {}
        },
    },
    data : () => ({
        title       : "Nuevo",
        valid       : true,
        lazy        : false,
        idUser      : -1,
        idPersona   : -1,
        nombre      : undefined,
        primerA     : undefined,
        segundoA    : undefined,
        correo      : undefined,
        perfil      : undefined,
        userName    : undefined,
        tipo        : undefined,
        password    : undefined,
        errores     : {
            nombre   : false,
            primerA  : false,
            segundoA : false,
            correo   : false,
            perfil   : false,
            userName : false,
            tipo     : false,
            password : false,
        },
        rules     : {
            nombre   : [v => !!v || 'El nombre es requerido'],
            primerA  : [v => !!v || 'El primer apellido es requerido'],
            segundoA : [], // [v => !!v || 'El segundo apellido es requerido'],
            correo   : [], // [v => !!v || 'El correo es requerido'],
            perfil   : [v => !!v || 'El perfil es requerido'],
            userName : [v => !!v || 'El nombre de usuario es requerido'],
            tipo     : [v => !!v || 'El tipo de usuario es requerido'],
            password : [v => !!v || 'La contrasela es requerida'],
        },
        items     : {
            perfil  : [],
            tipo    : []
        }
    }),
    watch : {
        'oldObject'  : function() {
            if ( Object.keys(this.oldObject).length > 0 ) {
                this.idUser    = this.oldObject.adminCtUser.id_adminCtUser;
                this.idPersona = this.oldObject.baseCtPersona.id_baseCtPersona;
                this.nombre    = this.oldObject.baseCtPersona.nombre;
                this.primerA   = this.oldObject.baseCtPersona.primerApellido;
                this.segundoA  = this.oldObject.baseCtPersona.segundoApellido;
                this.correo    = this.oldObject.adminCtUser.email;
                this.perfil    = this.oldObject.adminCtUser.id_adminCtPerfil;
                this.userName  = this.oldObject.adminCtUser.userName;
                this.tipo      = this.oldObject.adminCtUser.id_adminCtTipoUsuario;
            } else {
                this.idUser     = undefined;
                this.idPersona  = undefined;
                this.nombre     = undefined;
                this.primerA    = undefined;
                this.segundoA   = undefined;
                this.correo     = undefined;
                this.perfil     = undefined;
                this.userName   = undefined;
                this.tipo       = undefined;
            }
        }
    },
    computed : {
    },
    methods : {
        ...mapActions( "DialogForm", [ "setDialog" ] ),
        ...mapActions("User",  ["setDataSource"] ),
        getTitle() {
            return this.isNew ? "Nuevo usuario" : "Editar usuario";
        },
        eventClose () {
            this.reset();
            this.setDialog( {
                key    : "dlgUser",
                status : false
            } );
        },
        eventGen () {
            if( this.validate () )
                this.isNew ? this.createUser() : this.updateUser();
        },
        createUser() {
            let {adminCtUser, baseCtPersona} = this.buildObject();
            let arrInfo = []
            arrInfo.push(this.$JsTable.getFormat("adminCtUser",   "Insert", [ adminCtUser ],  ["id_adminCtUser"]));
            arrInfo.push(this.$JsTable.getFormat("baseCtPersona", "Insert", [ baseCtPersona ],  ["id_baseCtPersona"]));
            service.AdmC_user( arrInfo ).then( result => {
                if( this.$Msg.fn( {data : result, message : "Usuario creado correctamente" } )) {
                    this.eventClose();
                    this.readTableUsers();
                }
            })
        },
        updateUser() {
            let {adminCtUser, baseCtPersona} = this.buildObject();
            let arrInfo = []
            let ctUser    = this.$fn.getDiffObj( this.oldObject.adminCtUser,adminCtUser,["id_adminCtUser","updated_at"])
            let ctPersona = this.$fn.getDiffObj( this.oldObject.baseCtPersona, baseCtPersona,["id_baseCtPersona","updated_at"])
            if ( ctUser != 0 )
                arrInfo.push(this.$JsTable.getFormat("adminCtUser",   "Update", [ ctUser ],  ["id_adminCtUser"]));
            if ( ctPersona != 0 )
                arrInfo.push(this.$JsTable.getFormat("baseCtPersona", "Update", [ ctPersona ],  ["id_baseCtPersona"]));
            if ( arrInfo.length > 0 ) {
                service.AdmU_user( arrInfo ).then( result => {
                    if( this.$Msg.fn( {data : result, message : "Usuario actualizado correctamente" } )) {
                        this.eventClose();
                        this.readTableUsers();
                    }
                })
            } else {
                this.$Msg.error(`No se realizo ningun cambio`)
            }
        },
        buildObject(){
            let baseCtPersona = {
                id_baseCtPersona     : this.idPersona,
                id_baseCtTipoPersona : 1,   // Persona fisica
                nombre               : this.nombre,
                primerApellido       : this.primerA,
                segundoApellido      : this.segundoA,
                activo               : 1
            }

            let adminCtUser = {
                id_adminCtUser         : this.idUser,
                id_adminCtPerfil       : this.perfil,
                id_adminCtTipoUsuario  : this.tipo,
                id_baseCtPersona       : this.idPersona,
                name                   : this.nombre + " " + this.primerA + ( this.segundoA != undefined ) ? this.segundoA : " ", // Corrección para evitar el apellido materno: null
                email                  : this.correo,
                userName               : this.userName,
                activo                 : 1
            }
            if( this.isNew )
                adminCtUser.password = this.password;
            return {adminCtUser, baseCtPersona};
        },
        getTitle() {
            return this.isNew ? "Nuevo" : "Edición";
        },
        reset () {
            this.$refs.form.reset();
            this.idPersona = -1;
            this.idUser    = -1;
            this.idUser = -1;
        },
        validate () {
            return this.$refs.form.validate()
        },
        readTableUsers() {
            serviceS.SopR_dataSourceUsers().then( result => {
                this.setDataSource( this.$JsTable.toObj( result.data ))
            })
        },
    },
    created() {
        service.AdmR_dataCreateUser().then( result => {
            this.items.perfil = this.$JsTable.toObj( result.data.perfil );
            this.items.tipo   = this.$JsTable.toObj( result.data.tipo );
        })
    },
}
