<template>
    <div id="userMenu">
        <v-toolbar-title style="margin-right:15px"  class="caption text-center">
            {{user.name}}
            <v-subheader class="overline subUser">{{user.perfil}}</v-subheader>
        </v-toolbar-title>
        <v-menu bottom offset-y>
            <template v-slot:activator="{ on }">
                <v-avatar>
                    <v-btn class="iconAppbar" dark icon v-on="on">
                        <v-icon dark>mdi-account-circle</v-icon>
                    </v-btn>
                </v-avatar>
            </template>
            <v-list class="overline" dense>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon size="20">mdi-account-card-details</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="itemProfile">Cuenta</v-list-item-content>
                </v-list-item>                                    
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon size="20">mdi-settings</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="itemProfile">Ajustes</v-list-item-content>
                </v-list-item>                                                        
                <v-divider></v-divider>
                <router-link :to="'/logout'">
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon size="20">mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="itemProfile">Cerrar sesión</v-list-item-content>
                    </v-list-item>
                </router-link>
            </v-list>
        </v-menu>
    </div>
</template>
<script>
import { 
    createNamespacedHelpers 
} from 'vuex'
const {
    mapState
} = createNamespacedHelpers("auth")

export default {
    computed : {
        ...mapState(['user'])
    }
}
</script>

<style>
    #userMenu{
        display: flex   
    }
</style>