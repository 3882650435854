import setApi from '@/services/setApi';

const C = ( ( ) => {
    let parametros = {
        modulo  : 'administracion',
        abrev   : 'Adm',
        seccion : 'OPS',
        accion  : 'U',
        request : [
            /**
             * @description Inicializacion de datos de formularios para reportes 
             * su uso es dentro del store
             */
            "user",
            "passwordUser"
        ]
    }    
    return setApi(parametros)
})()

export default C