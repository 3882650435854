import ListModules from './complementos/DashBoardModulos.vue'
import SideMenu    from './complementos/DashBoardSideMenu.vue'
import UserMenu    from './complementos/DashBoardUserMenu.vue'
import NavLogo     from './complementos/DashBoardNavLogo.vue'
import AppBar      from './complementos/DashBoardAppBar.vue'
import { 
    createNamespacedHelpers 
} from 'vuex'
const {
    mapActions,
    mapGetters,
    mapState
} = createNamespacedHelpers("home")

export default {  
    props :{
        data : Object,
        drawerRight : {
            type    : Boolean,
            default : false
        } 
    },
    data : () => ({
        /* navLeft : {
            draw  : false,
            clip  : false,
            tempo : false,
            mini  : false,
            perma : false,
            icon  : "left",
            hover : false
        },  */    
        navRight : {
            draw : false,
            clip : true,
            mini : false
        },
        containerFluid : true
    }),
    components: { ListModules, SideMenu, UserMenu, NavLogo, AppBar},
    methods : {
        ...mapActions( ["setElements", "setInfo","setIdModulo"] ),
        toggleBtnPermantent(){
            this.navLeft.hover = !this.navLeft.hover
            this.navLeft.draw = !this.navLeft.draw
            this.navLeft.icon = this.navLeft.hover ? "right" : "left"
        },
        emitEvent( {idRlCA, idBtn} ) {
            this.$emit("loadEvent", {idRlCA, idBtn} )
        }
    },
    computed: {   
        currentElements: () => JSON.parse( localStorage.getItem('routes') ),        
        currentRouteName () { return this.$route.name; },
        display(){ return this.$mq == 'sm' ? false : true },
        ...mapGetters(["getElements", "getElementsById", "getElementsByIdConceptos"]),
        setBreadCrumbs() {
            var items = [];
            for (const key in this.data) {                
                items.push({
                    text : this.data[key].name,
                    disabled: true,
                    href: '/',
                })
            }            
            return items
        },
        ...mapState(["mainLoader", "navLeft"])
    },
    created() { 
        if( this.data != undefined )
            this.setIdModulo(this.data.mod.id)
        this.setElements( JSON.parse( localStorage.getItem('routes') ) )
    }
}
