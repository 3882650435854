<template> 
  <v-list class="sideMenuTitle">
        <v-list-item>
            <v-list-item-avatar tile>
                <v-img src="@/assets/img/Logo.png"></v-img>
            </v-list-item-avatar> 
            <v-list-item-content>
                <v-list-item-title class="title text-center sideMenuT">SIISER</v-list-item-title>
                <v-list-item-subtitle class=" text-center sideMenuSubT mask-w">Sistema Integral de Servicios</v-list-item-subtitle>                                            
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
export default {

}
</script>

<style>

</style>
