import { exportDataGrid } from 'devextreme/exporter/exceljs/excelExporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

export default {
    exporting : ( e, formatExcel ) => {
        var workbook = new ExcelJS.Workbook();
        var worksheet = workbook.addWorksheet( formatExcel.worksheet );
        let factor = 1;

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            customizeCell: options => {
                var gridCell = options.gridCell;
                var excelCell = options.excelCell;

                if (gridCell.rowType === 'data' && formatExcel.hasOwnProperty(gridCell.column.dataField) ) {
                    Object.assign(excelCell, {
                        font: {
                            color: {
                                argb: '000000'
                            }
                        },
                        /* fill: {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: 'FFFFFF'
                            }
                        }, */
                        numFmt : '#,##0.00'
                    });
                }

                if (gridCell.rowType === 'group' ) {
                    var nodeColors = ['BEDFE6', 'C9ECD7','CAEBF2','E3EFF2','EBF4F6'];
                    var numberPattern = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;

                    if ( excelCell.value != null && formatExcel.hasOwnProperty(gridCell.column.dataField) )
                        excelCell.value = parseFloat(excelCell.value.match( numberPattern ).join(''))
                    Object.assign(excelCell, {
                        fill: {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: nodeColors[gridCell.groupIndex]
                            }
                        },
                        numFmt : '#,##0.00'
                    });
                }
                if (gridCell.rowType === 'groupFooter' /* && excelCell.value */) {
                    Object.assign(excelCell, {
                        font   : { italic: true },
                        numFmt : '#,##0.00'
                    });
                }

                if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                    Object.assign(excelCell, {
                        numFmt : '#,##0.00'
                    });
                }

                if (formatExcel.hasOwnProperty(gridCell.column.dataField) && gridCell.value != undefined) {
                    factor = gridCell.rowType === 'totalFooter' ? formatExcel[gridCell.column.dataField].factorG : formatExcel[gridCell.column.dataField].factor
                    excelCell.value = gridCell.value * factor;
                }
            }
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(new Blob([buffer], {
                    type: 'application/octet-stream'
                }), formatExcel.fileName);
            });
        });
        e.cancel = true;
    }
}