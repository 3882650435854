//*----------  COMPONENTS  ----------*/
import DashBoard              from '@/components/DashBoard/DashBoard.vue'
import Create                 from './componentes/Create/Create.vue'
import ChangePass             from './componentes/ChangePass/ChangePass.vue'
import TabUsuarios            from './componentes/TabUsuarios/TabUsuarios.vue'

import { 
    mapActions
}                     from 'vuex';


var currentDate = new Date().toISOString().substr(0,10);
export default {
    name : "Usuario",
    components : {
        DashBoard,
        TabUsuarios,
        Create,
        ChangePass
    },
    props : {
        dataConcepto: Object
    },
    data : () => ({
       dialog    : false,
       dialogPass: false,
       isNew     : true,
       oldObject : {}
    }),
    computed : {
    },
    methods : {
        ...mapActions("DialogForm",["setDialog"]),
        loadEvent ( event, dataAction ) {
            switch( event.idBtn ) {
                case 'btnNuevo':
                    this.oldObject = {};
                    this.setDialog( {
                        key    : "dlgUser",
                        status : true
                    } );
                break;
                default:
                break;
            }
        },
        closeDialog() {
            this.dialog = false;
        },
        closeDialogPass() {
            this.dialogPass = false;
        },
        eventActionTable({ event, dataAction }) {
            this.$emit("eventActionTable", { event, dataAction })
            let data = event.row.data;
            let baseCtPersona   = {};
            let adminCtUser = {};
            switch( dataAction.accion ) {
                case "Edicion":
                    baseCtPersona = {
                        id_baseCtPersona     : data.id_baseCtPersona,
                        id_baseCtTipoPersona : data.id_baseCtTipoPersona,
                        nombre               : data.nombre,
                        primerApellido       : data.primerApellido,
                        segundoApellido      : data.segundoApellido,
                        activo               : 1
                    }

                    adminCtUser = {
                        id_adminCtUser        : data.id_adminCtUser,
                        id_adminCtPerfil      : data.id_adminCtPerfil,
                        id_adminCtTipoUsuario : data.id_adminCtTipoUsuario,
                        id_baseCtPersona      : data.id_baseCtPersona,
                        name                  : data.name,
                        email                 : data.email,
                        userName              : data.userName,
                        activo                : 1
                    }
                    this.oldObject = { baseCtPersona, adminCtUser };
                    this.isNew     = false;
                    this.setDialog( {
                        key    : "dlgUser",
                        status : true
                    } );
                    break;
                case "Cambio de contraseña":
                    baseCtPersona = {
                        id_baseCtPersona     : data.id_baseCtPersona,
                        id_baseCtTipoPersona : data.id_baseCtTipoPersona,
                        nombre               : data.nombre,
                        primerApellido       : data.primerApellido,
                        segundoApellido      : data.segundoApellido,
                        activo               : 1,
                        updated_at           : data.fechaU
                    }

                    adminCtUser = {
                        id_adminCtUser        : data.id_adminCtUser,
                        id_adminCtPerfil      : data.id_adminCtPerfil,
                        id_adminCtTipoUsuario : data.id_adminCtTipoUsuario,
                        id_baseCtPersona      : data.id_baseCtPersona,
                        name                  : data.name,
                        email                 : data.email,
                        userName              : data.userName,
                        activo                : 1
                    }
                    this.oldObject = { baseCtPersona, adminCtUser };
                    this.setDialog({
                        key    : "dlgChangePass",
                        status : true
                    });
                    break;
                default:
                    break;
            }
        },
    },
    created() {
     
    },
    mounted() {
    },
}
